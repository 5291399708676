.admin-dashboard {
  display: flex;
  height: auto;
}

.admin-main {
  display: flex;
  flex-direction: column;
  flex: 1;
}
