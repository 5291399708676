.sidebar {
  width: 250px;
  /* height: 100vh; */
  background-color: #145A98;
  color: white;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  overflow-y: auto; 
}

.sidebar .logo1 {
  background: url('../../assets/images/logo2.jpeg') center/cover no-repeat;
  width: 90px;
  height: 85px;
  margin-bottom: 15px;
  margin-right: 110px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

.sidebar ul li {
  padding: 10px;
  /* transition: background-color 0.3s; */
}

.sidebar ul li a {
  text-decoration: none;
  color: white;
  width: 100%;
  cursor: pointer;
}

/* .sidebar ul li:hover {
  background-color: #1e81ce;
} */

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    transform: translateX(-100%);
    width: 200px;
    padding: 20px;
    z-index: 10;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .menu-toggle {
    display: block;
    background-color: #145A98;
    color: white;
    font-size: 1.5rem;
    padding: 4px 15px;
    cursor: pointer;
    position: fixed;
    top: 15px;
    right: 15px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    transition: background-color 0.3s, transform 0.3s;
  }

  .menu-toggle:hover {
    background-color: #1e81ce;
    transform: scale(1.05);
  }
  .sidebar .logo1 {
    margin-right: 60px;
  }
}
