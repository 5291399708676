/* Add this CSS for the login page background */
.login-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* Change this to your desired background color */
    margin: 0;
    padding: 0;
}

.login-container {
    background: rgba(255, 255, 255, 0.9);
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    animation: fadeIn 1.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
}

.signin-form {
    width: 300px;
}

.signin-form h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    color: #333;
    font-weight: bold;
}

.input-group {
    margin-bottom: 1rem;
}

.input-group label {
    display: block;
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.input-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    transition: border 0.3s;
}

.input-group input:focus {
    border: 1px solid #000;
    outline: none;
}

.fbtn button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    background: #145A98;
    color: white;
    font-size: 1.25rem;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s;
}

.fbtn button:hover {
    background: #1e81ce;
    transform: scale(1.05);
}
