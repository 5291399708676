.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 15px;
  border-bottom: 1px solid #dee2e6;
}

.admin-header-right {
  display: flex;
  align-items: center;
}

.admin-header-right span {
  margin-left: 10px;
}

.admin-header-right ul{
  list-style: none;
  padding: 0;
  width: 100%;
}

.admin-header-right ul li {
  padding: 10px;
  cursor: pointer;
  background-color: #1e81ce;
  border-radius: 5px;
}
.admin-header-right ul li a {
  text-decoration: none;
  color: white;
}

/* .admin-header-right ul li:hover {
  background-color: #1e81ce;
} */

@media (max-width: 768px) {
  .admin-header-right ul li {
    margin-right: 70px;
  }
}