.admin-content {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

.card {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  padding: 20px;
  margin: 10px;
  flex: 1;
  min-width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .cards {
    flex-direction: column;
  }

  .card {
    min-width: 100%;
  }
}